import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Privacy from "../components/privacy"

const IndexPage = () => {

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Privacy />
    </Layout>
  )

}


export default IndexPage


