import React from 'react'

import Link from 'gatsby-link'

import classes from './privacy.module.scss'

const Privacy = () => (

    <section id="privacy" className={classes.privacy}>

        <h1>Privacy Policy</h1>
        <p>
            If you require additional information or have any
            questions about our privacy policy, please feel free
        to contact us by email at <a href="mailto:galanocp@gmail.com"><u>galanocp@gmail.com</u></a>
        </p>

        <p>
            At <Link to="/"><u>Pistachio</u></Link> we take seriously the privacy of visitors to our
        website. This privacy policy document describes in detail the types of personal information
        that are collected and recorded by <Link to="/"><u>Pistachio</u></Link> and how we use it.
        </p>

        <h2>Log Files</h2>
        <p>
            Like many other Web sites, <Link to="/"><u>Pistachio</u></Link> makes use of log files.
        These files merely log visitors to the site—usually a standard procedure
        for hosting companies and a part of hosting services's analytics. The
        information inside the log files includes internet protocol (IP) addresses,
        browser type, Internet Service Provider (ISP), date/time stamp,
        referring/exit pages, and possibly the number of clicks. This information
        is used to analyze trends, administer the site, track user's movement around
        the site, and gather demographic information. IP addresses, and other such
        information are not linked to any information that is personally identifiable.
        </p>

        <h2>Cookies and Web Beacons</h2>
        <p>
            <Link to="/"><u>Pistachio</u></Link> uses cookies to store information about visitors'
        preferences, to record user-specific information on which pages the site
        visitor accesses or visits, and to personalize or customize our web page
        content based upon visitors' browser type or other information that the
        visitor sends via their browser.
        </p>

        <p>
            If you wish to disable cookies, you may do so through your individual
            browser options. More detailed information about cookie management with
            specific web browsers can be found at the browsers' respective websites.
        </p>

        <h2>Children's Information</h2>
        <p>
            We believe it is important to provide added protection for children online.
            We encourage parents and guardians to spend time online with their children
            to observe, participate in and/or monitor and guide their online activity.
        <Link to="/"> <u>Pistachio</u></Link> does not knowingly collect any personally identifiable
        information from children under the age of 13. If a parent or guardian believes
        that <Link to="/"><u>Pistachio</u></Link> has in its database the personally-identifiable
        information of a child under the age of 13, please contact us immediately
        (using the contact in the first paragraph) and we will use our best efforts
        to promptly remove such information from our records.
        </p>

        <h2>Google Analytics</h2>
        <p>
            <Link to="/"><u>Pistachio</u></Link> makes use of Google Analytics for which you can opt in
        or opt out at any time. You can read more about Google Analytics at their
        <a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=ca" target="_blank" rel="noopener noreferrer" > <u>website</u></a>.
        </p>

    </section>

)

export default Privacy